<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <el-card class="box-card">
                <!-- 按钮 -->
                <el-row style="height:40px;">
                    <el-button type="primary" @click="add" size="small" class="buttons">新增</el-button>
                </el-row>
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="创建时间">
                        <div class="block">
                              <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.start"
                                size="small"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd 00:00:00"
                                placeholder="开始时间">
                                </el-date-picker>
                            </div>
                            |&nbsp;&nbsp;&nbsp;&nbsp;
                            <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                
                                <el-date-picker
                                size="small"
                                v-model="req.end"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd 23:59:59"
                                type="date"
                                placeholder="结束时间">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                        size="small"
                                placeholder="请输入关键词搜索"
                                prefix-icon="el-icon-search"
                                v-model="req.keywords"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit" size="small">搜索</el-button>
                        <!-- <el-button @click="onReset">重置</el-button> -->
                    </el-form-item>
                </el-form>
                <!-- 树状图 -->
               <el-row>
                    <el-tree
                    ref="tree1"
                    :data="tableData"
                    :render-content="renderContent"
                    node-key="id"
                    :highlight-current="true"
                    :default-expand-all="true"
                    :indent="50"
                    :props="defaultProps">
                    </el-tree>
               </el-row>
            </el-card>
        </div>

        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="添加分类"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="60%"
        center>
            <createShare :id="currentId" :pid="pid" :parentName="parentName" :currentName="currentName" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>

        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList :id="currentId" ></shareList>
        </el-dialog>
    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
export default {
props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            //上传图片列表地址
            uploadImages: [

            ],
            createShareFlag:false,
            shareListFlag:false,
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            req:{
                limit:30,
                page:1,
                keywords:''
            },
            defaultProps:{
                children:'child',
                label: 'name'
            },
            pid:0,
            parentName:'',
        }
    },
    components:{
        createShare,shareListFlag
    },
    methods:{
        onSubmit(){

        },
        renderContent(h, { node, data, store }) {
            return (
            <span class="custom-tree-node" style="display:inline-block;min-height:35px;">
                <span>{node.label}</span>
                <span>
                <el-button size="mini" type="text" class="el-icon-plus c_button" 
                title="新增下级分类"
                style="margin:0 0 0 20px;font-size:15px;" on-click={ () => this.append(data) }></el-button>
                <el-button size="mini" type="text" class="el-icon-edit c_button" 
                title="修改分类"
                style="margin:0 0 0 10px;font-size:15px;" on-click={ () => this.edit(data) }></el-button>
                <el-button size="mini" type="text" class="el-icon-delete c_button" 
                title="删除该分类"
                style="margin:0 0 0 10px;font-size:15px;"
                on-click={ () => this.remove(node, data) }></el-button>
                </span>
            </span>);
        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            apis.goodsCateList(this.req).then((res) => {
                this.tableData = res.data;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        append(data){
            this.pid = data.id;
            this.parentName = data.name;
            this.id = 0;
            this.createShareFlag = true;
        },
        add(){
            this.pid = 0;
            this.parentName = '';
            this.id = 0;
            this.createShareFlag = true;
        },
        remove(node, data) {
            let id = data.id;
            this.$confirm('确定删除此数据?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.goodsCateDelete({ id: id}).then((res) => {
                    if(tools.msg(res,this)){
                        this.$refs.tree1.remove(node.key);
                    }
                })
            }).catch((err) => {
                // tools.err(err,this);
            });
        },
        edit(data){
            this.createShareFlag = true;
            this.currentId = data.id;
        }
    },
    created(){
        console.log(apis);
        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}

</style>