<template>
  <div>
       <!-- 创建 -->
        <div style="width:500px;margin:0 auto;background:#fff;">
            <div style="text-align:left;">
                <el-row v-if="pid>0">
                    <p style="width:100%;font-size:14px;color:blue;">上级：{{parentName}}</p>
               </el-row>
                <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;">分类名称：</p>
                    <el-input
                    style="width:250px;"
                    placeholder="请输入内容"
                    v-model="saveData.name"
                    clearable>
                    </el-input>
               </el-row>
            </div>
            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button type="primary"  @click="save();">保 存</el-button>
            </div>
        </div> 
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        },
        pid:{
            type:Number,
            default:0
        },
        parentName:{
            type:String,
            default:''
        },
        currentName:{
            type:String,
            default:''
        }
    },
    data(){
        return {
            saveComplete:false,
            imageUrl:'',
            saveData:{
                name:"",
                pid:0
            }
        }
    },
    methods:{
        copy(code,item){

        },
        beforeUpload(){
            this.uploadLoading = true;
        },
        save(){
            //判断是修改还是新增
            if(this.id > 0){
                this.saveData.id = this.id;
                //保存  
                apis.goodsCateUpdate(this.saveData).then((res)=>{
                    //通知列表页更新数据
                    tools.msg(res,this,1) && this.fleshList();
                }).catch((err)=>{
                    console.log(err);
                    this.$message('网络不通，请稍候重试-');
                });                
                
                return;
            }else{
                //是否有父级
                this.saveData.pid = this.pid;
            }
            //新增
            apis.addGoodsCate(this.saveData).then((res)=>{
                //通知列表页更新数据
                tools.msg(res,this,1) && this.fleshList();
                this.saveData.name = '';

            }).catch((err)=>{
                this.$message('网络不通，请稍候重试-');
            });

        },
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        }
    },
    destroyed(){
        delete this.id;
        delete this.pid;
        delete this.parentName;
    },
    created(){
        //判断是修改还是新增
        if(this.id > 0){
            //初始化修改数据
            apis.goodsCateDetail({id:this.id}).then((res)=>{
                this.saveData = res.data;
            }).catch((err)=>{
                tools.err(err,this);
            });
        }
    }
}
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px;
    text-align: center;
  }
  .avatar {
    width: 78px;
    height: 78px;
    display: block;
  }
</style>